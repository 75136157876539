import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Button} from 'reactstrap';
import Widget from '../../components/Widget';
import APIServer from '../../utils/ServerApi';
import { toast } from "react-toastify";

class VerifyEmail extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    static isAuthenticated(token) {
        if (token) return true;
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            options: {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
            },
            errorMessage: null,
            resetEabled: false,
            resetCount: 0, 
        };

    }

    componentDidMount = () => {
        this.checkResendEmail()
    }
    countTime = ()=>{
        if(this.state.resetCount ==0) {
            this.setState({
                ...this.state,
                resetEabled: true
            });
        }else {
            if(this.state.resetEabled)
                this.setState({
                    ...this.state,
                    resetEabled: false,
                    resetCount: this.state.resetCount -1
                });
            setTimeout(()=>{
                this.countTime()
            },  1000)
        }

    }
    checkResendEmail = () => {
        APIServer.get('/api/auth/resend-email-point').then(res => {
            if(res.data.point === undefined){
                this.setState({
                    ...this.state, 
                    resetCount:0, 
                    resetEabled: true
                })
                return; 
            }
            const resetTime = new Date(res.data.point);
            const curTime = new Date;
            const timeDiff = curTime.getTime() - resetTime.getTime();
            if(timeDiff< 600* 1000){
                this.setState({
                    ...this.state,
                    resetCount:Math.floor(600- timeDiff/1000),
                    resetEabled: false
                })
            }else{
                this.setState({
                    ...this.state, 
                    resetEabled: true, 
                    resetCount: 0
                })
            }
        }).catch(e=>{

        })
    }

    resendVerifyEmail = () => {
        if(this.state.resetEabled){
            this.setState({
                ...this.state,
                resetEabled: false
            })
            APIServer.post('/api/auth/resend-email').then(res => {
                toast.success("We resent verification email. Check your email.")
                this.setState({
                    ...this.state ,
                    resetCount: 600000
                })
                this.countTime();
            })
        }
    }
    render() {
        const {
            onFacebookAuth,
            onAppleAuth,
        } = this.props

        return (
            <div className="auth-page">
                <a href="https://exxomarkets.com"><span class="login-logo"></span></a>
                <div className='auth-header'>
                    <div class="link-to-homepage-container">
                        <a href={`${process.env.REACT_APP_PUBLIC_URL}`} target="_blank">
                            Please click here to go to our homepage
                        </a>
                    </div>
                </div>
                <Container>
                    <Widget className="widget-auth mx-auto login-body" title={<h4 className="mt-0"><strong>
                        Please check your mail box.</strong></h4>}>
                        <button className={"border-rounded exxo-btn-primary w-100  " } onClick={this.resendVerifyEmail} 
                                disabled = {!this.state.resetEabled}
                            >
                                {
                                    this.state.resetEabled && "Resend request" || `Wait for ${this.state.resetCount > 60 ? ""+ Math.floor( this.state.resetCount/60) +" mins" : "" + this.state.resetCount%60+ " secs"} to resend email`
                                }
                        </button>
                    </Widget>
                </Container>
                <footer className="auth-footer" style={{ position: "absolute" }}>
                    <div class="text-center">
                        © 2022 <a href={`${process.env.REACT_APP_PUBLIC_URL}`} target="blank">Exxo Markets</a><br />
                        All Rights Reserved
                    </div>
                </footer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default connect(mapStateToProps)(VerifyEmail);

