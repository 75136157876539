
import Cookies from "js-cookie"
import React from "react";

const linkIndex = "ExxomarketsIbLink";

class Referral extends React.Component {
    componentDidMount() {
        const { ibLinkId } = this.props.match.params;
        if (!!ibLinkId) {
            const cur_date = new Date();
            cur_date.setDate(cur_date.getDate() + 30);
            Cookies.set(linkIndex, ibLinkId, { expires: cur_date });
            window.location.href = `${process.env.PUBLIC_URL}/register`; 
        }
    }
    render() {
        return (
            <>
            </>
        )
    }
}

export default Referral; 