export const FACEBOOK_APP_ID = "853466218454989"
export const APPLE_CLIENT_ID = "com.news.webapp"
export const GOOGLE_CLIENT_ID =
  "719823468477-ph43fjjnu7754geh2qjf8e9uh4m40efg.apps.googleusercontent.com"

export const IBStatus = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
  NEW: "NEW"
}

export const KYCStatus = {
  APPROVED: "VERIFIED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  NEW: "NEW",
  DELETED: "DELETED"
}

export const WithdrawStatus ={
  WITHDRAWAL_DONE: "DONE",
  WITHDRAWAL_APPROVED: "PROCESSING",
  WITHDRAWAL_FAILED: "FAILED", 
  WITHDRAWAL_NEW: "NEW"
}

export const SocialStatus = {
  APPROVED: "APPROVED", 
  DECLINED: "DECLINED",
  PENDING: "PENDING",
  NEW: "NEW"
}

export const CryptoDepositStatus = {
  DEPOSIT_NEW: "New", 
  DEPOSIT_PENDING: "Pending", 
  DEPOSIT_CNACELD: "Canceled", 
  DEPOSIT_SUCCESS: "Success", 
  DEPOSIT_COMPLETE: "Complete", 
  DEPOSIT_FAILED: "Failed"
}
