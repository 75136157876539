import axios from "axios";

const config = {
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
};

const APIServer = axios.create(config);

const authInterceptor = (config) => {
	const token = localStorage.getItem("authenticated");
	config.headers.Authorization = token;
	return config;
};

APIServer.interceptors.request.use(authInterceptor);
APIServer.interceptors.response.use((response) => {

	if(response.data.success === false){
		return Promise.reject(response.data.error); 
	}
	return response;

}, (error) => { // Anything except 2XX goes to here
	const status = error.response?.status || 500;
	process.env.REACT_APP_MODE === "local" && process.env.REACT_APP_MODE === "local" && console.log('status', status);
	if (status === 401) {
		localStorage.clear();
		window.location = window.location.protocol + "//" + window.location.host + "/login"
	}
	return Promise.reject(error); 
});
export default APIServer;
